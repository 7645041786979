import { createSelector } from 'reselect';
import { get, isBoolean, isEmpty, filter, some, zipObject, split, compact } from 'lodash';
import { API_FAILURE } from '../actions';
import { ODELL_HIERARCHY } from '../routes';

// Selector for page title
export const titleSelector = (state, key) => get(state, key, null);
export const getTitle = createSelector(titleSelector, title => (title ? `Louisiana Curriculum Hub - ${title}` : 'Louisiana Curriculum Hub'));

// Selector for loading indicator
export const loadersSelector = (state, filters) => (!state.loaders[API_FAILURE] ? filters.map(f => state.loaders[f]) : [false]);
export const getLoaders = createSelector(loadersSelector, loaders => some(loaders, Boolean));
export const getStrictLoaders = createSelector(loadersSelector, loaders => {
  return isEmpty(filter(loaders, l => isBoolean(l))) || some(loaders, Boolean);
});

// Selector for auth
export const getDevise = state => state.devise;

// Selector for grades
export const getGrades = state => state.nav.grades;

// Selectore for meta
export const getMeta = state => state.meta;

// Get active path by hierarchy
export const getActivePath = createSelector(
  state => state.pathname,
  path => {
    return zipObject(ODELL_HIERARCHY, compact(split(path, '/')));
  }
);

// Selector for guidebookType
const guidebookSelector = state => (state.resource.odellCurriculumType === 'guidebook' ? state.resource : get(state.resource, 'guidebook'));
export const getGuidebook = createSelector(guidebookSelector, guidebook => guidebook);
export const getGuidebookType = createSelector(guidebookSelector, guidebook => get(guidebook, 'guidebookType', 'unknown'));
