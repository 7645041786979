import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toLower } from 'lodash';
import { linkWithParams } from '../../routes';
import { rawHtml } from '../../utils/Formatters';

const ActivityCard = ({ resource }) => {
  const activity = resource.activity;
  const activityType = toLower(activity.activityType);
  const clsActivityType = {
    'u-od-cs-bg--gb-extra u-od-cs-txt--light': activityType !== 'optional',
    'u-od-cs-border--gb-extra u-od-cs-txt--gb-extra': activityType === 'optional',
  };
  const clsNumber = classNames('o-od-activity-card__number u-od-txt--activity-card-number text-center', clsActivityType);
  const clsType = classNames('o-od-item-card__label  u-od-txt--lesson-card-type text-center', clsActivityType);
  const pacingCls = classNames('u-od-cs-bg--gb-extra u-od-cs-txt--light o-od-item-card__label  u-od-txt--lesson-card-type text-center u-od-margin-left--small');
  const pacingValue = activity.pacing ? `${activity.pacing} min` : 'HW';
  return (
    <div className="o-od-item-card u-od-cs-bg--gb-light u-od-link--light">
      <Link to={{ pathname: linkWithParams('activity', resource.hierarchy) }}>
        <div className="row is-collapse-child align-middle align-justify u-od-margin-bottom--base">
          <div className="col-md-6">
            <div className={clsNumber}>{activity.number}</div>
          </div>
          <div className="col-md-6">
            <div className={clsType}>{activity.activityType}</div>
            <div className={pacingCls}>{pacingValue}</div>
          </div>
        </div>
        <div className="u-od-cs-txt--gb-extra u-od-padding-left--zero">
          <p className="u-od-txt--activity-card-header u-margin-bottom--small">
            {activity.framework}: {activity.frameworkPart}
          </p>
          {rawHtml(activity.aboutTheActivityStudent.default, 'u-od-txt--content-card')}
        </div>
      </Link>
    </div>
  );
};

ActivityCard.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default ActivityCard;
