import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getGuidebook } from '../../selectors';
import Header from '../../components/nav/Header';

const mapStateToProps = state => {
  return {
    guidebook: getGuidebook(state),
  };
};

const SubHeader = connect(mapStateToProps, { push })(Header);

export default SubHeader;
