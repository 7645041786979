import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Sticky } from 'react-sticky';
import { get, indexOf, isEmpty, map, slice, sortBy } from 'lodash';
import { appNamedRoutes, ODELL_BREADCRUMB_HIERARCHY } from '../../routes';
import BreadCrumbDropdown from './BreadCrumbDropdown';
import iconHome from 'assets/images/icons/home-icon.svg';

class BreadCrumb extends React.Component {
  static propTypes = {
    breadcrumb: PropTypes.object.isRequired,
    guidebookType: PropTypes.string.isRequired,
    resourceType: PropTypes.string,
    withSticky: PropTypes.bool,
    // actions
    push: PropTypes.func.isRequired,
  };

  renderSticky = ({ style }) => {
    const { breadcrumb, guidebookType, push, resourceType } = this.props;
    const activePath = get(breadcrumb, 'result.active');
    const currHierarchy = slice(ODELL_BREADCRUMB_HIERARCHY, 0, activePath.length);
    const curriculumOrder = idx => get(breadcrumb, idx === 0 ? 'result.data' : `entities.${ODELL_BREADCRUMB_HIERARCHY[idx - 1]}.${activePath[idx - 1]}.children`, []);
    const breadCrumbs = map(currHierarchy, (b, idx) => {
      const level = get(breadcrumb, `entities.${currHierarchy[idx]}`);
      const active = get(level, `${activePath[idx]}.resource`);
      const order = curriculumOrder(idx);
      const data = sortBy(
        map(level, l => l.resource),
        l => indexOf(order, l.id)
      );
      return (
        <li key={`bc-${b}`} className={`o-od-breadcrumb o-od-breadcrumb--${guidebookType} u-od-padding-bottom--base`}>
          <BreadCrumbDropdown active={active} guidebookType={guidebookType} data={data} push={push} />
        </li>
      );
    });
    return (
      <div className={`u-od-cs-bg--${guidebookType} o-od-breadcrumb__wrap o-od-breadcrumb__wrap--${resourceType}`} style={style}>
        <div className="row u-od-padding-top--base">
          <div className="columns">
            <ul className={`o-od-breadcrumb-group o-od-breadcrumb-group--${guidebookType}`}>
              <li key="bc-home" className={`o-od-breadcrumb o-od-breadcrumb--${guidebookType} u-od-padding-bottom--base `}>
                <Link to={appNamedRoutes.root} className="o-od-breadcrumb--home u-od-txt--breadcrumb u-od-cs-txt-link--light u-od-custom-link">
                  <img className="u-od-margin-right--small" src={iconHome} alt="HOME" />
                  Home
                </Link>
              </li>
              {breadCrumbs}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (isEmpty(this.props.breadcrumb)) return null;
    const { guidebookType, withSticky } = this.props;
    if (withSticky) {
      return (
        <div className={`u-od-cs-bg--${guidebookType}`}>
          <Sticky>{this.renderSticky}</Sticky>
        </div>
      );
    }
    return this.renderSticky({ style: {} });
  }
}

export default BreadCrumb;
