import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { getLoaders } from '../selectors';
import { loadHomePage } from '../actions';
import HomeContainer from './home/HomeContainer';
import TopNavBar from './nav/TopNavBar';
import { gaPageView } from '../utils/analytics';
import Error from '../components/Error';

const titleHome = 'Louisiana Curriculum Hub';

class HomePage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    // actions
    loadHomePage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadHomePage();
    gaPageView(titleHome);
  }

  render() {
    return (
      <DocumentTitle title={titleHome}>
        <div>
          <TopNavBar />
          <Error />
          {!this.props.isLoading && <HomeContainer />}
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: getLoaders(state, ['GRADES']),
  };
};

export default connect(mapStateToProps, { loadHomePage })(HomePage);
