import React from 'react';
import Layout from './Layout';
import 'isomorphic-fetch';
import _ from 'lodash';

const title = 'Interactive Supports';

class SupportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
      selected: {},
      categories: [],
    };

    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.clickCategory = this.clickCategory.bind(this);
  }

  async fetchData() {
    try {
      this.setState({ ...this.state, isLoading: true });
      const response = await fetch(Routes.api_v1_supports_path());
      const jsonData = await response.json();
      this.setState({ data: jsonData, isLoading: false });
    } catch (_e) {
      console.log(_e);
      this.setState({ ...this.state, isLoading: false });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  setSelectedOption = id => event => {
    if (event.target.checked) {
      this.setState({ ...this.state, selected: { ...this.state.selected, [id]: (this.state.selected[id] || 0) + 1 } });
    } else {
      this.setState({ ...this.state, selected: { ...this.state.selected, [id]: (this.state.selected[id] || 0) - 1 } });
    }
  };

  clickCategory = category => _event => {
    if (this.state.categories.includes(category)) {
      this.setState({ ...this.state, selected: {}, categories: this.state.categories.filter(c => c !== category) });
    } else {
      this.setState({ ...this.state, selected: {}, categories: [category] });
    }
  };

  render() {
    const categories = _.filter(_.uniq(this.state.data.map(d => d.category)), v => !_.isNil(v));

    let options = _.flatMap(
      this.state.data.map(d => {
        return d.observations.map(o => {
          return {
            label: o,
            value: `${d.id} - ${o}`,
            id: d.id,
            category: d.category,
          };
        });
      })
    );

    options = _.filter(options, d => d.category && this.state.categories.includes(d.category));

    return (
      <Layout title={title} withTopBar={true} withSubBar={true} isLoading={this.state.isLoading}>
        <div className="c-supports">
          <h1>
            <div>Interactive Supports</div>
          </h1>
          <p className="description">
            The goal of English language arts (ELA) is for all students to read, understand, and express their understanding of complex, grade-level texts. As described in the{' '}
            <a target="_blank" href="https://www.louisianabelieves.com/docs/default-source/teacher-toolbox-resources/diverse-learners-guide-2022.pdf?sfvrsn=2f726518_2" rel="noreferrer">
              Diverse Learners Guide
            </a>
            , the guidebook lessons include supports to ensure students reach this goal. Diverse learners may also need additional supports. This document includes information, guidance, and supports
            to use with individual or a small group of students during regular classroom instruction or for more intensive intervention outside of regular classroom instruction.
          </p>
          <p className="description">To determine the best support, select from a category below. Then you will select the observations you have made and supports will be displayed.</p>

          {categories.length > 0 ? (
            <ul className="categories">
              {categories.map(c => (
                <li className={this.state.categories.includes(c) ? 'selected' : null} key={c} onClick={this.clickCategory(c)}>
                  <img src={require(`../../assets/images/supports/${c.toLowerCase()}.png`)} alt={c} />
                  <span className="name">{c}</span>
                </li>
              ))}
            </ul>
          ) : null}

          {options.length > 0 ? (
            <table>
              <tr>
                <td width={'30%'}>
                  <table className="observation-column">
                    <tr>
                      <th>
                        <div>Observations</div>
                      </th>
                    </tr>
                    {options.map(o => (
                      <tr key={o.value}>
                        <td>
                          <div>
                            <label>
                              <input name={o.value} type="checkbox" onChange={this.setSelectedOption(o.id)}></input>
                              <span dangerouslySetInnerHTML={{ __html: o.label }}></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                </td>
                <td>
                  <table>
                    <tr>
                      <th width={'50%'}>Possible Issues</th>
                      <th>
                        <div>Possible Supports</div>
                      </th>
                    </tr>
                    {this.state.data
                      .filter(d => this.state.selected[d.id])
                      .map(d => (
                        <tr className="divider" key={d.id}>
                          <td>
                            <div dangerouslySetInnerHTML={{ __html: d.possible_issues }}></div>
                          </td>
                          <td>
                            <div dangerouslySetInnerHTML={{ __html: d.possible_supports }}></div>
                          </td>
                        </tr>
                      ))}
                  </table>
                </td>
              </tr>
            </table>
          ) : null}
        </div>
      </Layout>
    );
  }
}

export default SupportsPage;
