import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, isEmpty, map } from 'lodash';
import { rawHtml } from '../../utils/Formatters';
import iconDigitalAccess from 'assets/images/materials/text-digital-access.svg';
import iconUnitReader from 'assets/images/materials/text-unit-reader.svg';
import iconBook from 'assets/images/materials/text-trade-book.svg';
import iconCDDVD from 'assets/images/materials/text-dvd-cd.svg';
import iconUnknown from 'assets/images/materials/text-digital-access.svg';
import iconDownloads from 'assets/images/icons/download-materials.svg';

const materialIcons = {
  // Access Types from text list
  'digital-access': { icon: iconDigitalAccess, title: 'Digital Access' },
  'purchase-unit-reader': { icon: iconUnitReader, title: 'Unit Reader	Purchase' },
  'purchase-book': { icon: iconBook, title: 'Purchased Trade Book' },
  'purchase-cd': { icon: iconCDDVD, title: 'Purchase (CD)' },
  'purchase-dvd': { icon: iconCDDVD, title: 'Purchase (DVD)' },
};

const materialDownloadIcons = {
  ad: 'one-time-use-tool',
  ar: 'one-time-use-tool',
  aw: 'one-time-use-tool',
  ap: 'one-time-use-tool',
  co: 'one-time-use-tool',
  cs: 'one-time-use-tool',
  cx: 'checklist',
  da: 'one-time-use-tool',
  dg: 'one-time-use-tool',
  dx: 'checklist',
  ea: 'one-time-use-tool',
  ei: 'one-time-use-tool',
  et: 'one-time-use-tool',
  eu: 'one-time-use-tool',
  ew: 'one-time-use-tool',
  fc: 'one-time-use-tool',
  lw: 'one-time-use-tool',
  ms: 'print-text',
  nt: 'multi-use-tool',
  os: 'one-time-use-tool',
  qs: 'question-set',
  so: 'one-time-use-tool',
  vc: 'one-time-use-tool',
  vj: 'multi-use-tool',
  vl: 'multi-use-tool',
  wh: 'one-time-use-tool',
  wm: 'one-time-use-tool',
  wt: 'one-time-use-tool',
  // Generated content types for materials
  gdoc: 'gdoc',
  pdf: 'pdf',
};

const MATERIAL_TYPES = ['pdf', 'gdoc'];

const iconDownload = iconDef => (
  <svg className="o-od-icon-image o-od-icon-image--material u-od-cs-txt--grade">
    <use href={`${iconDownloads}#${iconDef}`} />
  </svg>
);

const TextMaterialsList = ({ materials, meta, textList }) => {
  const texts = map(textList, t => {
    const k = t.accessType;
    const title = t.title;
    const iconTitle = get(materialIcons, `${k}.title`, title);
    return (
      <tr key={`tlg-${t.textId}`}>
        <td className="o-od-textlist__icon text-center">
          <img className="o-od-icon-image--material" src={get(materialIcons, `${k}.icon`, iconUnknown)} alt={iconTitle} title={iconTitle} />
        </td>
        <td>{rawHtml(title, '', 'span')}</td>
      </tr>
    );
  });
  const downloads = map(materials, m => {
    const links = map(MATERIAL_TYPES, t => {
      const url = get(m.links, t);
      if (url) {
        const iconTitle = `Download ${t.toUpperCase()}`;
        const iconDef = t === 'gdoc' ? get(materialDownloadIcons, t) : get(materialDownloadIcons, m.type) || get(materialDownloadIcons, t);
        return (
          <a href={url} target="_blank" rel="noopener noreferrer" key={`mlg-${m.id}-${t}`} title={iconTitle}>
            {iconDownload(iconDef, iconTitle)}
          </a>
        );
      }
      return null;
    });
    return (
      <tr key={`mlg-${m.id}`}>
        <td className="o-od-textlist__icon text-center">{links}</td>
        <td>
          <span>{m.title}</span>
        </td>
      </tr>
    );
  });
  const isMaterialsEmpty = isEmpty(texts) && isEmpty(downloads);
  const clsSlideContent = classNames('o-od-slide__content o-od-slide__content--materials', {
    'o-od-slide__content--fullscreen-materials': meta.fullScreen,
    'o-od-slide__content--base-materials': !meta.fullScreen,
    'o-od-materials-blank__wrap': isMaterialsEmpty,
  });
  return (
    <div className={clsSlideContent}>
      {!isMaterialsEmpty && (
        <table className="o-od-textlist u-od-margin-top--base u-od-txt--slide-materials">
          <tbody>
            {texts}
            {downloads}
          </tbody>
        </table>
      )}
      {isMaterialsEmpty && (
        <div className="o-od-materials-blank u-od-cs-bg--light  u-od-txt--slide-materials">
          <p className="text-center">There are no materials for this activity.</p>
        </div>
      )}
    </div>
  );
};

TextMaterialsList.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta: PropTypes.object.isRequired,
  textList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TextMaterialsList;
