import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ActivityTab extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    children: PropTypes.object,
    // actions
    loadFullScreen: PropTypes.func.isRequired,
  };

  toggleFullScreen = _e => {
    this.props.loadFullScreen(!this.props.meta.fullScreen);
  };

  render() {
    const { children, meta } = this.props;
    const clsBase = classNames('o-od-slide u-od-cs-bg--gb-light u-od-cs-txt--gb-extra', { 'o-od-slide--base': !meta.fullScreen });
    const clsIcon = classNames('u-od-txt--icon-large u-od-pointer', { 'od-icon-expand': !meta.fullScreen }, { 'od-icon-shrink': meta.fullScreen });
    return (
      <div className={clsBase}>
        <div className="o-od-slide__header u-od-link">
          <i className={clsIcon} onClick={this.toggleFullScreen}></i>
        </div>
        {children}
      </div>
    );
  }
}

export default ActivityTab;
