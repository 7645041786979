import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, range } from 'lodash';
import { Link } from 'react-router-dom';
import { linkWithParams } from '../../routes';

class ActivityControls extends React.Component {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    segment: PropTypes.object.isRequired,
    // actions
    changeActivitySegment: PropTypes.func.isRequired,
  };

  dotSegment = e => {
    const number = parseInt(e.target.dataset.id, 10);
    this.props.changeActivitySegment(number, this.props.segment.idx > number ? 'left' : 'right');
    e.preventDefault();
  };

  renderDots() {
    const { idx, total } = this.props.segment;
    const dots = map(range(total), i => {
      const cls = classNames('o-od-slide-control__dot', { 'u-od-cs-bg--slide-selected-dot': i + 1 === idx }, { 'u-od-cs-bg--slide-dot': i + 1 !== idx });
      return (
        <li key={`sdot-${i}`}>
          <button onClick={this.dotSegment} data-id={i + 1}>
            <span className={cls} data-id={i + 1}></span>
          </button>
        </li>
      );
    });
    return (
      <li className="shrink">
        <ul className="o-od-slide-control__items">{dots}</ul>
      </li>
    );
  }

  render() {
    const { activity, segment } = this.props;
    return (
      <ul className="o-od-slide-control">
        <li className="shrink">
          <ul className="o-od-slide-control__items">
            <li>
              {activity.nav.previous && (
                <Link to={{ pathname: linkWithParams(activity.nav.previous.odellCurriculumType, activity.nav.previous.hierarchy) }}>
                  <i className="u-od-txt--icon-base od-icon-left-arrow u-od-cs-txt--gb-extra" aria-hidden="true"></i>
                </Link>
              )}
            </li>
          </ul>
        </li>
        {segment.total > 1 && this.renderDots()}
        <li className="shrink">
          <ul className="o-od-slide-control__items">
            <li>
              {activity.nav.next && (
                <Link to={{ pathname: linkWithParams(activity.nav.next.odellCurriculumType, activity.nav.next.hierarchy) }}>
                  <i className="u-od-txt--icon-base od-icon-right-arrow u-od-cs-txt--gb-extra" aria-hidden="true"></i>
                </Link>
              )}
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

export default ActivityControls;
