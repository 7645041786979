import * as ActionTypes from '../actions';
import * as EventTypes from '../actions/internal';

const errorDefaultState = { message: null, messageType: 'alert', status: false };

const errorMessage = (state = errorDefaultState, action) => {
  switch (action.type) {
    case ActionTypes.API_FAILURE: {
      const { error } = action.payload;
      return {
        message: error || 'Unknow error',
        messageType: 'alert',
        status: true,
      };
    }
    case ActionTypes.AUTH.RECEIVE:
      return errorDefaultState;
    case EventTypes.SIGN_OUT: {
      const { error } = action.payload;
      return error ? { message: error, messageType: 'alert', status: true } : errorDefaultState;
    }
    case EventTypes.RESET_ERROR_MESSAGE:
      return { message: null, messageType: 'alert', status: action.meta.status };
    default:
      return state;
  }
};

export default errorMessage;
