import React from 'react';

const HomeText = () => (
  <div className="row c-ld-home-text">
    <div className="col">
      <h1 className="c-ld-title">Welcome to ELA Guidebooks!</h1>

      <ul>
        <li>Click on grades 3-5 to access the digital ELA Guidebooks (2022) units.</li>
        <li>Click on grades 9-12 to access the digital ELA Guidebooks (2020) units.</li>
        <li>Click on grades 3-8 to download the ELA Guidebooks (2018) units using the bulk downloader tool.</li>
      </ul>

      <p>
        The bulk downloader tool allows you to download full units of content from the ELA Guidebooks to your personal Google Drive. When you click “Authorize,” you give permission to the bulk
        downloader to create a new ELA Guidebooks unit folder in your Google Drive. The units, when downloaded, are organized into folders and all the files are editable, rather than view only. This
        allows you to make any needed changes to the ELA Guidebook materials without having to download each individual lesson and document. This also allows you to upload the unit content into Google
        Classroom more easily, as you will have an organized unit on your Drive from which you can create assignments and quizzes in Google Classroom.
      </p>

      <p>
        View the <a href="/privacy-policy">privacy policy</a> related to the bulk downloader tool.
      </p>
    </div>
  </div>
);

export default HomeText;
