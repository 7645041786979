import { GoogleDrive, retry } from './utils';

const MESSAGES = {
  error: 'Error occurred!',
  finish: 'Check your drive in a few minutes',
  signIn: 'You need to sign in first.',
  start: 'Click Google Drive logo to start',
  working: 'Working...',
};

class Api {
  tokenClient;

  async init() {
    await gapi.client.init({
      apiKey: window.BULK_DOWNLOADER_CREDENTIALS.API_KEY,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    });

    retry(
      () => {
        this.tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: window.BULK_DOWNLOADER_CREDENTIALS.CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/drive.file',
          callback: '',
        });
      },
      500,
      3
    );
  }

  handleAuth(callback) {
    this.tokenClient.callback = async (response) => {
      if (response.error !== undefined && response.error !== 'access_denied') {
        throw response;
      }
      await callback.call();
    };

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  async getFolderId(targetFolderName, useServiceAccountPermissions = true) {
    // Check that folder doesn't exist
    let targetFolderId = await GoogleDrive.folderExists(targetFolderName, 'root', gapi.client.drive);
    if (targetFolderId === null) {
      // Create target folder inside user's root
      targetFolderId = await GoogleDrive.createFolder(targetFolderName, 'root', gapi.client.drive);
      // Update its permissions for Service Account
      if (useServiceAccountPermissions) {
        await GoogleDrive.changePermissions(
          targetFolderId,
          'user',
          'writer',
          window.BULK_DOWNLOADER_CREDENTIALS.SA_EMAIL,
          gapi.client.drive
        );
      }
    }

    return targetFolderId;
  }

  async start(sourceFolderId) {
    const targetFolderName = window.BULK_DOWNLOADER_CREDENTIALS.TARGET_FOLDER_NAME;
    const targetFolderId = await this.getFolderId(targetFolderName);

    const apiUrl = `${window.BULK_DOWNLOADER_CREDENTIALS.API_ENDPOINT}/start`;
    return fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sourceFolderId, targetFolderId }),
    });
  }

  async startS3Transfer(s3Folder, accessToken) {
    const driveFolderName = window.BULK_DOWNLOADER_CREDENTIALS.TARGET_FOLDER_NAME;
    const driveFolderId = await this.getFolderId(driveFolderName, false);

    const response = await fetch(window.BULK_DOWNLOADER_CREDENTIALS.API_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ driveFolderId, s3Folder, accessToken }),
    });

    // throw an error if the lambda invoke failed
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error);
    }

    return driveFolderId;
  }
}

export { Api, MESSAGES };
