import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { linkWithParams } from '../../routes';

const LevelArrows = ({ resource, children }) => {
  const previous = resource.nav.previous;
  const next = resource.nav.next;
  return (
    <div className="row align-middle">
      <div className="columns">{children ? children : <h1>{resource.title}</h1>}</div>
      {false && (
        <div className="columns shrink u-od-txt--nav-arrows u-od-cs-txt--arrow">
          {previous && (
            <Link className="" to={{ pathname: linkWithParams(previous.odellCurriculumType, previous.hierarchy) }}>
              <i className="u-od-txt--icon-base od-icon-left-arrow" aria-hidden="true"></i>&nbsp;{previous.title}
            </Link>
          )}
          {next && (
            <Link className="u-od-padding-left--gutter" to={{ pathname: linkWithParams(next.odellCurriculumType, next.hierarchy) }}>
              {next.title}&nbsp;<i className="u-od-txt--icon-base od-icon-right-arrow" aria-hidden="true"></i>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

LevelArrows.propTypes = {
  children: PropTypes.object,
  resource: PropTypes.object.isRequired,
};

export default LevelArrows;
