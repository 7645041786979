import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import { linkWithParams } from '../../routes';

const getLinkToBlock = g => {
  return g.id ? { pathname: linkWithParams('grade', g.hierarchy) } : '#';
};

const HomeGrades = ({ data }) => {
  if (isEmpty(data.grades)) return null;
  const blocks = map(data.grades, g => {
    const link = getLinkToBlock(g);
    return (
      <div key={`g-${g.id}`} className="u-od-margin-top--gutter u-od-margin-bottom--gutter">
        <Link to={link}>
          <div className="o-od-card-grade u-od-cs-bg--ldoe-light-orange align-center">
            <div className="o-od-card-grade__number u-od-txt--home-grade align-middle">{g.number}</div>
          </div>
        </Link>
      </div>
    );
  });
  return (
    <div className="u-od-cs-bg--ldoe-light-gray o-od-page__wrap row">
      <div className="columns large-12">
        <div className="o-od-card-grades-container">{blocks}</div>
      </div>
    </div>
  );
};

HomeGrades.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HomeGrades;
