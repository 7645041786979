import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isStudentSubdomain } from '../../routes';
import HomeGrades from './HomeGrades';
import HomeTeacherGuide from './HomeTeacherGuide';
import HomeHero from './HomeHero';
import HomeText from './HomeText';

const Home = ({ devise, data, s3BucketName }) => {
  if (isEmpty(data.grades)) return null;
  return (
    <div className="c-od-home">
      <HomeHero s3BucketName={s3BucketName} urls={data.landingUrls} />
      <HomeGrades data={data} />
      {!isStudentSubdomain(devise) && <HomeTeacherGuide />}
      <HomeText />
    </div>
  );
};

Home.propTypes = {
  data: PropTypes.object.isRequired,
  devise: PropTypes.object.isRequired,
  s3BucketName: PropTypes.string.isRequired,
};

export default Home;
