import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { linkWithParams } from '../../routes';
import { rawHtml } from '../../utils/Formatters';
import gbApplication from '../../../assets/images/pages/grades-application.svg';
import gbDevelopment from '../../../assets/images/pages/grades-development.svg';
import gbFoundation from '../../../assets/images/pages/grades-foundation.svg';
import gbIWU from '../../../assets/images/pages/grades-iwu.svg';

const guidebookIcons = {
  application: gbApplication,
  development: gbDevelopment,
  foundation: gbFoundation,
  iwu: gbIWU,
};

const GradeGuidebook = ({ guidebook }) => {
  const cls = classNames('u-od-margin-bottom--gutter u-od-flex-inline align-middle', { 'u-od-disabled': !guidebook.id });
  const link = guidebook.id ? { pathname: linkWithParams('guidebook', guidebook.hierarchy) } : '#';
  return (
    <li>
      <Link className={cls} to={link}>
        <div>
          <img src={guidebookIcons[guidebook.guidebookType]} alt={guidebook.guidebookType} />
        </div>
        <div className={`u-od-padding-left--gutter-half u-od-cs-txt-link--${guidebook.guidebookType}`}>
          {guidebook.title && (
            <div className="u-od-txt--guidebook-header">
              <strong>{guidebook.title}</strong>
            </div>
          )}
          {guidebook.metadata.question && rawHtml(guidebook.metadata.question, 'u-od-txt--guidebook-subheader')}
        </div>
      </Link>
    </li>
  );
};

GradeGuidebook.propTypes = {
  guidebook: PropTypes.object.isRequired,
};

export default GradeGuidebook;
