import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, map } from 'lodash';
import { iconHeader, rawHtml } from '../../utils/Formatters';
import { isStudentSubdomain } from '../../routes';
import LevelArrows from '../nav/LevelArrows';
import ActivityCard from './ActivityCard';

const Lesson = ({ devise, guidebookType, resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'lesson') return null;
  const isStudent = isStudentSubdomain(devise);
  const activities = map(resource.activities, s => <ActivityCard key={`sl-${s.id}`} resource={s} isStudent={isStudent} />);
  return (
    <div className={`u-od-colorset--${guidebookType} row u-od-margin-top--gutter2x u-od-margin-bottom--gutter align-center`}>
      <div className="columns small-12 o-od-page--content">
        <LevelArrows resource={resource} />
        {rawHtml(resource.description, 'u-od-txt--content-teaser u-od-cs-txt--gb-dark u-od-margin-top--gutter')}

        <hr className="u-od-hline u-od-margin-top--gutter" />
        <h2 className="o-od-header--image">
          {iconHeader('icon-target')}
          <b>Lesson Look Fors</b>
        </h2>
        {rawHtml(resource.lookFors, 'u-od-txt--content')}

        <hr className="u-od-hline u-od-margin-top--gutter" />
        <h2 className="o-od-header--image">
          {iconHeader('icon-activity')}
          <b>Activities</b>
        </h2>
        {activities}
      </div>
    </div>
  );
};

Lesson.propTypes = {
  devise: PropTypes.object.isRequired,
  guidebookType: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Lesson;
