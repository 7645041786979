import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { StickyContainer } from 'react-sticky';
import SubHeader from './nav/SubHeader';
import SubNavBar from './nav/SubNavBar';
import TopNavBar from './nav/TopNavBar';
import Error from '../components/Error';
// import { LoadingSpinner } from '../components/LoadingSpinner'

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    resourceType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    withSubHeader: PropTypes.bool.isRequired,
    withSticky: PropTypes.bool.isRequired,
    withTopBar: PropTypes.bool.isRequired,
  };

  render() {
    const { resourceType, title, withSticky, withSubHeader, withTopBar } = this.props;
    const WrapElement = withSticky ? StickyContainer : 'div';
    return (
      <DocumentTitle title={title}>
        <div>
          {withTopBar && <TopNavBar />}
          <WrapElement className={`o-od-page__wrap o-od-page__wrap--${resourceType}`}>
            <SubNavBar withSticky={withSticky} resourceType={resourceType} />
            {withSubHeader && <SubHeader />}
            <Error />
            {this.props.children}
          </WrapElement>
        </div>
      </DocumentTitle>
    );
  }
}

export default Layout;
