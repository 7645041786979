import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, map } from 'lodash';
import { iconHeader, rawHtml } from '../../utils/Formatters';
import LevelArrows from '../nav/LevelArrows';
import LessonCard from './LessonCard';

const Section = ({ guidebookType, resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'section') return null;
  const lessons = map(resource.children, s => <LessonCard key={`sl-${s.id}`} resource={s} guidebookType={guidebookType} />);
  return (
    <div className={`u-od-colorset--${guidebookType} row u-od-margin-top--gutter2x u-od-margin-bottom--gutter align-center`}>
      <div className="columns small-12 o-od-page--content">
        <LevelArrows resource={resource} />
        {rawHtml(resource.teaser, 'u-od-txt--content-teaser u-od-cs-txt--gb-dark u-od-margin-top--gutter')}

        <hr className="u-od-hline u-od-margin-top--gutter" />
        <h2 className="o-od-header--image">{resource.nav.next ? <b>Section Diagnostic</b> : <b>Culminating Task</b>}</h2>
        {rawHtml(resource.metadata.assessment, 'u-od-txt--content')}

        <hr className="u-od-hline u-od-margin-top--gutter" />
        <h2 className="o-od-header--image">
          {iconHeader('icon-lesson')}
          <b>Lessons</b>
        </h2>
        {lessons}
      </div>
    </div>
  );
};

Section.propTypes = {
  guidebookType: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Section;
