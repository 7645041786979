import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';
import createRootReducer from '../reducers';

export default function configureStore(history, props) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(createRootReducer(history), props, applyMiddleware(sagaMiddleware, routerMiddleware(history)));

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
