function delay(ms, operation) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(operation ? operation() : null);
    }, ms);
  });
}

function retry(operation, delayTime = 2000, retries = 1) {
  let result;
  try {
    result = operation();
  } catch (error) {
    if (retries > 0) {
      const wait = delayTime * Math.random() * 5;
      delay(wait).then(() => {
        retry(operation, wait, retries - 1);
      });
    } else {
      throw error;
    }
  }
  return result;
}

class GoogleDrive {
  //
  // @param {string} name
  // @param {string} parentId
  // @param {object} driveService An instance of gapi.client.drive Google Javascript API
  // @returns {string} ID of the created folder
  //
  static async createFolder(name, parentId, driveService) {
    const folderMetadata = {
      name,
      mimeType: 'application/vnd.google-apps.folder',
      parents: [parentId],
    };

    // Create a folder on the target account
    const response = await driveService.files.create({ resource: folderMetadata });
    const targetId = response.result.id;
    console.log('Folder created, ID: ', targetId);

    return targetId;
  }

  //
  // Change permission of the folder with the email address and set
  // the required role (owner, writer, reader) and type (user, group, domain, anyone)
  //
  // @param {string} folderId
  // @param {string} type One of user, group, domain, anyone
  // @param {string} role One of owner, writer, reader
  // @param {string} emailAddress
  // @param {object} driveService An instance of gapi.client.drive Google Javascript API
  // @returns {string} ID of the updated folder
  //
  static async changePermissions(folderId, type, role, emailAddress, driveService) {
    const permission = { type, role, emailAddress };
    const response = await driveService.permissions.create({
      fileId: folderId,
      resource: permission,
    });
    console.log('Folder permissions updated: ', response);

    return folderId;
  }

  //
  // @param {string} name
  // @param {string} parentId
  // @param {object} driveService An instance of gapi.client.drive Google Javascript API
  // @returns {string} ID of the existing folder
  //
  static async folderExists(name, parentId, driveService) {
    let q = `name='${name}' and trashed=false`;
    if (parentId) {
      q += `and '${parentId}' in parents`;
    }
    const response = await driveService.files.list({ q, fields: 'files(id)' });
    if (response.result.files.length > 0) {
      const targetId = response.result.files[0].id;
      console.log('Target Folder already exists, ID: ', targetId);

      return targetId;
    }
    return null;
  }

  static urlToId(url) {
    const parsed = new URL(url);

    if (url.match(/\/open/)) {
      return parsed.searchParams.get('id');
    }

    return parsed.pathname.split('/').at(-1);
  }
}

export { delay, retry, GoogleDrive };
