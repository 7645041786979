import { action } from './index';

// Slides related events
export const CHANGE_ACTIVITY = 'CHANGE_ACTIVITY';
export const CHANGE_ACTIVITY_SEGMENT = 'CHANGE_ACTIVITY_SEGMENT';
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN';
// Resets the currently visible error message.
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
// Reset store on signout
export const SIGN_OUT = 'SIGN_OUT';

export const changeActivity = activityNumber => action(CHANGE_ACTIVITY, { activityNumber, segmentNumber: 1 });
export const changeActivitySegment = (segmentNumber, segmentEffect) => action(CHANGE_ACTIVITY_SEGMENT, { segmentNumber, segmentEffect });
export const resetErrorMessage = (meta = { status: false }) => action(RESET_ERROR_MESSAGE, {}, meta);
export const resetStore = error => action(SIGN_OUT, error);
export const toggleFullScreen = mode => action(TOGGLE_FULL_SCREEN, { mode });
