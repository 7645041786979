import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadIwusPage } from '../actions';
import { getLoaders } from '../selectors';
import { gaPageView } from '../utils/analytics';
import Layout from './Layout';
import IwusContainer from './iwus/IwusContainer';

const titleIwus = 'ODELL - IWUs';

class IwusPage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    rest: PropTypes.object.isRequired,
    // actions
    loadIwusPage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadIwusPage();
    gaPageView(titleIwus);
  }

  render() {
    return (
      <Layout title={titleIwus} withTopBar={true} withSubBar={true} isLoading={this.props.isLoading}>
        <IwusContainer />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: getLoaders(state, ['BREADCRUMB', 'IWUS']),
  };
};

export default connect(mapStateToProps, { loadIwusPage })(IwusPage);
