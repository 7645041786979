import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetErrorMessage } from '../actions/internal';

class Error extends Component {
  static propTypes = {
    message: PropTypes.string,
    messageType: PropTypes.string,
    status: PropTypes.bool.isRequired,
    resetErrorMessage: PropTypes.func.isRequired,
  };

  handleDismissClick = e => {
    this.props.resetErrorMessage();
    e.preventDefault();
  };

  render() {
    const { message, messageType, status } = this.props;
    if (message && status) window.scrollTo(0, 0);
    return message && status ? (
      <div className="row">
        <div className="column">
          <div className={`callout ${messageType === 'notice' ? 'success' : messageType} u-od-margin-top--large u-od-margin-bottom--base`}>
            {message}
            <button className="close-button" aria-label="Dismiss alert" type="button" onClick={this.handleDismissClick}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  message: ownProps.message || state.errorMessage.message,
  messageType: ownProps.messageType || state.errorMessage.messageType,
  status: state.errorMessage.status,
});

export default connect(mapStateToProps, { resetErrorMessage })(Error);
