import React from 'react';
import iconHeaders from 'assets/images/icons/content-headers.svg';

export const rawHtml = (html, cls = '', htmlTag = 'div') => {
  const Tag = htmlTag;
  return <Tag className={cls} dangerouslySetInnerHTML={{ __html: html }} />;
};

export const iconHeader = iconDef => (
  <svg className="o-od-icon-image o-od-icon-image--header u-od-cs-txt--gb-dark u-od-margin-right--base">
    <use href={`${iconHeaders}#${iconDef}`} />
  </svg>
);
