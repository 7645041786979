import React from 'react';

const HomeTeacherGuide = () => {
  return (
    <div className="o-od-page__wrap">
      <div className="u-od-padding-top--gutter u-od-padding-bottom--gutter row">
        <div className="columns large-9 small-6">
          <span className="u-od-txt--teaser">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
        </div>
        <div className="columns">
          <a
            href="http://odell-static.s3.amazonaws.com/TPG.pdf"
            target="_blank"
            rel="noopener noreferrer"
            title="Program Guide"
            className="o-od-card o-od-card-tg u-od-txt--home-grade-caption u-od-cs-txt-link--light u-od-cs-bg--grade text-center"
          >
            <span className="u-od-padding-h--base">TEACHER PROGRAM GUIDE</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeTeacherGuide;
