import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import GradeGuidebook from '../grades/GradeGuidebook';

const Iwus = ({ resource }) => {
  if (isEmpty(resource)) return null;
  const iwuGuidebooks = map(resource.children, g => <GradeGuidebook key={`gb-${g.guidebookType}-${g.id}`} guidebook={g} />);
  return (
    <div className="row">
      <div className="columns">
        <h1>
          <strong>{resource.title}</strong>
        </h1>
        <div className="u-od-margin-top--gutter u-od-margin-bottom--gutter2x">{iwuGuidebooks}</div>
      </div>
    </div>
  );
};

Iwus.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Iwus;
