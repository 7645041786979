import React from 'react';
import PropTypes from 'prop-types';
import { each, replace } from 'lodash';
import { Route } from 'react-router-dom';

export const appNamedRoutes = {
  about: '/about',
  curriculumGuide: '/curriculum-guide',
  purchasing: '/purchasing',
  root: '/',
  logout: '/logout',
  grade: '/:grade',
  guidebook: '/:grade/:guidebook',
  iwus: '/iwus',
  section: '/:grade/:guidebook/:section',
  lesson: '/:grade/:guidebook/:section/:lesson',
  activity: '/:grade/:guidebook/:section/:lesson/:activity',
};

export const ODELL_HIERARCHY = ['grade', 'guidebook', 'section', 'lesson', 'activity'];
export const ODELL_BREADCRUMB_HIERARCHY = ['grades', 'guidebooks', 'sections', 'lessons', 'activities'];
export const IWU_HIERARCHY_TYPE = 'iwus';

export const s3Url = (bucketName, key) => `https://${bucketName}.s3.amazonaws.com/${key}`;

export const paramsToSlug = params => {
  let slug = '';
  each(ODELL_HIERARCHY, h => {
    if (params[h]) slug += `/${params[h]}`;
    else return;
  });
  return slug;
};

export const linkWithParams = (routeType, params = {}) => {
  let path = appNamedRoutes[routeType];
  each(params, (v, k) => {
    path = replace(path, `:${k}`, v);
  });
  return path;
};

export const isStudentSubdomain = () => true;

class ExtendedRoute extends React.Component {
  static propTypes = {
    component: PropTypes.elementType.isRequired,
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        /* eslint-disable react/jsx-no-bind */
        render={props => <Component {...props} rest={rest} />}
      />
    );
  }
}

export default ExtendedRoute;
