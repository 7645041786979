import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { isEmpty, get, isArray } from 'lodash';
import classNames from 'classnames';
import { isStudentSubdomain } from '../../routes';
import { rawHtml } from '../../utils/Formatters';
import { getSegment } from './ActivityHelpers';
import ActivityHeader from './ActivityHeader';
import TeacherNotes from './TeacherNotes';
import ActivitySlides from './ActivitySlides';
import ActivityTab from './ActivityTab';
import TextMaterialsList from './TextMaterialsList';

const Activity = ({ devise, guidebookType, resource, meta, changeActivitySegment, loadFullScreen }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'activity') return null;
  const { activity, content } = resource;
  const isStudent = isStudentSubdomain(devise);
  const segmentLength = isArray(content.student.segment) ? content.student.segment.length : 1;
  const currenSegment = getSegment(content, meta.segmentNumber, 'student');
  const clsActivity = classNames('o-od-activity__common columns small-12 u-od-padding-top--base', { 'large-6': !meta.fullScreen });
  const clsActivityTab = classNames('o-od-activity-tabs', { 'u-od-margin-top--large': !meta.fullScreen });
  return (
    <section className={`u-od-colorset--${guidebookType} row u-od-margin-top--gutter u-od-margin-bottom--gutter`}>
      <div className="columns small-12 o-od-activity-list--triangle">
        <ActivityHeader resource={resource} meta={meta} isStudent={isStudent} />
      </div>
      <div className={clsActivity}>
        {!meta.fullScreen && rawHtml(activity.aboutTheActivityStudent.default, 'u-od-txt--content u-od-cs-txt--gb-extra')}
        <Tabs defaultIndex={0} className={clsActivityTab}>
          <TabList className="o-od-activity-tabs__tab-list">
            <Tab className="o-od-activity-tabs__tab text-center u-od-link" selectedClassName="u-od-cs-border-tab u-od-cs-txt--gb-extra">
              Directions
            </Tab>
            <Tab className="o-od-activity-tabs__tab text-center u-od-link" selectedClassName="u-od-cs-border-tab u-od-cs-txt--gb-extra">
              Materials
            </Tab>
          </TabList>
          <TabPanel className="o-od-activity-tabs__tab-panel">
            <ActivityTab meta={meta} loadFullScreen={loadFullScreen}>
              <ActivitySlides
                activity={{ idx: meta.activityNumber, purpose: activity.readingPurpose.default, nav: resource.nav }}
                segment={{ idx: meta.segmentNumber, data: currenSegment, total: segmentLength }}
                meta={meta}
                changeActivitySegment={changeActivitySegment}
              />
            </ActivityTab>
          </TabPanel>
          <TabPanel>
            <ActivityTab meta={meta} loadFullScreen={loadFullScreen}>
              <TextMaterialsList textList={content.textList} materials={resource.materials} meta={meta} />
            </ActivityTab>
          </TabPanel>
        </Tabs>
      </div>
      {!meta.fullScreen && (
        <div className="o-od-activity__teacher columns small-12 large-6">
          <TeacherNotes segmentNumber={meta.segmentNumber} activity={activity} content={content} guidebookType={guidebookType} />
        </div>
      )}
    </section>
  );
};

Activity.propTypes = {
  guidebookType: PropTypes.string.isRequired,
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  // actions
  changeActivitySegment: PropTypes.func.isRequired,
  loadFullScreen: PropTypes.func.isRequired,
};

export default Activity;
