import { take, fork } from 'redux-saga/effects';
import { gaPageView } from '../utils/analytics';
import { getTitle } from '../selectors';
import * as ActionTypes from '../actions';

export function* watchAPIError() {
  while (true) {
    yield take(ActionTypes.API_FAILURE);
    gaPageView('API.ERROR');
  }
}

export function* watchResources() {
  while (true) {
    const data = yield take([ActionTypes.ACTIVITY.RECEIVE, ActionTypes.RESOURCES.RECEIVE]);
    gaPageView(getTitle(data.payload, 'title'));
  }
}

export const rootAnalytics = () => [fork(watchResources), fork(watchAPIError)];
