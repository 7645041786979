import { connect } from 'react-redux';
import { changeActivitySegment } from '../../actions/internal';
import { loadFullScreen } from '../../actions';
import { getGuidebookType } from '../../selectors';
import Activity from '../../components/activities/Activity';

const mapStateToProps = state => {
  return {
    guidebookType: getGuidebookType(state),
    devise: state.devise,
    meta: state.meta,
    resource: state.resource,
  };
};

const ActivityContainer = connect(mapStateToProps, { changeActivitySegment, loadFullScreen })(Activity);

export default ActivityContainer;
