import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { appNamedRoutes, isStudentSubdomain } from '../../routes';

const TopBar = ({ devise, logout }) => {
  return (
    <header className="o-od-page__wrap u-od-txt--header u-od-cs-txt--light u-od-cs-bg--primary">
      <div className="o-od-header--top row align-middle">
        <div className="columns">
          <Link to={appNamedRoutes.root} className="u-od-custom-link u-od-txt--uppercase">
            <h1>Louisiana Curriculum Hub</h1>
          </Link>
        </div>
        {!isStudentSubdomain(devise) && (
          <div className="columns shrink">
            <a href="http://odell-static.s3.amazonaws.com/TPG.pdf" target="_blank" rel="noopener noreferrer" title="Program Guide">
              <span className="hide-for-medium">GUIDE</span>
              <span className="hide-for-small-only">TEACHER PROGRAM GUIDE</span>
            </a>
            <button className="u-od-cs-txt-link--light u-od-margin-left--large" onClick={logout} title="Logout">
              LOGOUT
            </button>
          </div>
        )}
        <div className="columns shrink">
          <a href="https://www.louisianabelieves.com/academics/ela-guidebooks" className="u-od-cs-txt-link--light">
            About
          </a>
        </div>
      </div>
    </header>
  );
};

TopBar.propTypes = {
  devise: PropTypes.object,
  logout: PropTypes.func,
};

export default TopBar;
