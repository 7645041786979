import * as ActionTypes from '../actions';

export default function breadcrumb(state = {}, action) {
  switch (action.type) {
    case ActionTypes.BREADCRUMB.RECEIVE:
      return action.payload;
    default:
      return state;
  }
}
