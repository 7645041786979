import React from 'react';
import PropTypes from 'prop-types';
import { getSegment } from './ActivityHelpers';
import { rawHtml } from '../../utils/Formatters';

const TeacherNotes = ({ activity, content, guidebookType, segmentNumber }) => {
  const pacing = activity.pacing ? <div>{activity.pacing} min</div> : '';
  const teacherSegmentData = getSegment(content, segmentNumber, 'teacher');
  return (
    <div className="o-od-teacher-notes">
      <div
        className={`o-od-teacher-notes__header o-od-teacher-notes__header--${guidebookType} u-od-cs-txt--gb-dark u-od-padding-h--base u-od-txt--content-header text-center o-od-teacher-notes--with-pacing`}
      >
        <div>Teaching Notes</div>
        {pacing}
      </div>
      <div className="o-od-teacher-notes__body u-od-cs-txt--dark-gray u-od-txt--slide-notes u-od-padding-h--base u-od-padding-v--base">
        {rawHtml(activity.classConfigurationTeacher.default)}
        {rawHtml(teacherSegmentData)}
      </div>
    </div>
  );
};

TeacherNotes.propTypes = {
  activity: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  guidebookType: PropTypes.string.isRequired,
  segmentNumber: PropTypes.number.isRequired,
};

export default TeacherNotes;
