import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, filter } from 'lodash';
import GradeGuidebook from './GradeGuidebook';

const gradeSlugs = {
  'ela/grade-3': 3,
  'ela/grade-4': 4,
  'ela/grade-5': 5,
  'ela/grade-6': 6,
  'ela/grade-7': 7,
  'ela/grade-8': 8,
  'ela/grade-9': 9,
  'ela/grade-10': 10,
  'ela/grade-11': 11,
  'ela/grade-12': 12,
};

const filteredGuidebooks = (guidebooks, type) => filter(guidebooks, ['guidebookType', type]).map((g, idx) => <GradeGuidebook key={`gb-${g.guidebookType}-${idx}-${g.id}`} guidebook={g} />);
const encodeFolderName = name => encodeURIComponent(name).replace(/%2F/g, '%252F');

const Grade = ({ resource }) => {
  const gDriveContent = resource.googleDriveContent;
  const gDriveShow = gDriveContent && gDriveContent['show'];
  const gDriveFolders = gDriveContent && gDriveContent['folders'];

  const resourceId = resource['id'];

  useEffect(() => {
    if (typeof initBulkDownloader !== 'undefined') {
      initBulkDownloader();
    }
  }, [resourceId]);

  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'grade') return null;

  const guidebooks = resource.children;
  const foundationGuidebooks = filteredGuidebooks(guidebooks, 'foundation');
  const developmentGuidebooks = filteredGuidebooks(guidebooks, 'development');
  const applicationGuidebooks = filteredGuidebooks(guidebooks, 'application');

  let googleDriveContent;

  if (gDriveShow && gDriveFolders) {
    const gradeNumber = resource.title.split(' ')[1].padStart(2, '0');
    googleDriveContent = gDriveFolders.map(folder => {
      const unitPart = folder.title.split(':')[0];
      const s3Folder = `${encodeFolderName(`Grade ${gradeNumber}`)}/${encodeFolderName(unitPart)}`;
      return (
        <div key={folder['title']} className="c-od-bulk-downloader-section">
          <a href={folder['url']} data-s3-folder={s3Folder} className="c-magic-link c-magic-link--s3-transfer o-hidden">
            {folder['title']}
          </a>
          <br />
        </div>
      );
    });
  }

  return (
    <div className={`row c-od-grade__bg c-od-grade--${gradeSlugs[resource.slug]}`}>
      <div className="columns">
        <h1>{resource.title}</h1>
        <ul className="no-bullet u-od-margin-top--gutter">
          {foundationGuidebooks}
          {developmentGuidebooks}
          {applicationGuidebooks}

          {googleDriveContent}
        </ul>
      </div>
    </div>
  );
};

Grade.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Grade;
