import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { loadResourcePage } from '../actions';
import { getLoaders, getTitle } from '../selectors';
import Layout from './Layout';
import ResourceContainer from './resource/ResourceContainer';

class ResourcePage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    rest: PropTypes.object.isRequired,
    title: PropTypes.string,
    // actions
    loadResourcePage: PropTypes.func.isRequired,
  };

  initialize(meta = {}) {
    this.props.loadResourcePage(this.props.match.params, meta);
  }

  componentDidMount() {
    this.initialize({ source: 'init' });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.match.params, this.props.match.params)) {
      this.initialize();
    }
  }

  render() {
    const { resourceType, withSubHeader, withSticky } = this.props.rest;
    return (
      <Layout title={this.props.title} withSticky={withSticky} withTopBar={true} withSubHeader={withSubHeader} isLoading={this.props.isLoading} resourceType={resourceType}>
        <ResourceContainer resourceType={resourceType} />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: getLoaders(state, ['BREADCRUMB', 'RESOURCES']),
    title: getTitle(state.resource, 'title'),
  };
};

export default connect(mapStateToProps, { loadResourcePage })(ResourcePage);
