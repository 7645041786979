import React from 'react';
import PropTypes from 'prop-types';
import { appNamedRoutes } from '../routes';

const NotFound = ({ location }) => (
  <div className="o-od-page__wrap">
    <div className="row">
      <div className="column u-od-margin-top--large">
        <h1>Not Found</h1>
        <p>
          The requested URL <code>{location.pathname}</code> was not found. <br />
          Please, try to <a href={appNamedRoutes.root}>start</a> again.
        </p>
      </div>
    </div>
  </div>
);

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotFound;
