import { normalize } from 'normalizr';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { get } from 'lodash';
import 'isomorphic-fetch';
import Routes from '../utils/routes.js.erb';
import { Schemas } from './schemas';

const plainHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint, options = {}) {
  const { body, headers, method, schema } = options;
  return fetch(endpoint, {
    method: method || 'GET',
    body: body ? JSON.stringify(decamelizeKeys(body)) : undefined,
    headers: headers || plainHeaders,
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) return Promise.reject({ status: response.status, data: json });
      const camelizedJson = camelizeKeys(json);
      return Object.assign({}, schema ? normalize(camelizedJson, schema) : camelizedJson);
    })
    .then(
      response => ({ response }),
      error => ({ error: get(error, 'data.message') || get(error, 'message') || get(error, 'data.error') || 'Something bad happened', status: error.status })
    );
}

// api services
export const fetchBreadcrump = params => callApi(Routes.api_v1_breadcrumb_index_path(params), { schema: Schemas.BREADCRUMB });
export const fetchGrades = () => callApi(Routes.api_v1_grades_path());
export const fetchIwus = () => callApi(Routes.iwus_api_v1_grades_path());
export const fetchActivity = params => callApi(Routes.api_v1_activities_path(params));
export const fetchResources = params => callApi(Routes.api_v1_resources_path(params));
