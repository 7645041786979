import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get, isEmpty, map } from 'lodash';
import { linkWithParams } from '../../routes';
import { rawHtml } from '../../utils/Formatters';
import materialIcons from '../../../assets/images/icons/guidebook-materials.svg';
import urlParser from 'js-video-url-parser';

const MaterialIcons = {
  unit_evaluation_plan: 'icon-evaluation-plan',
  unit_text_overview: 'icon-text-overview',
};

const Guidebook = ({ guidebookType, resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'guidebook') return null;
  const sections = map(resource.children, s => (
    <div key={`gs-${s.id}`} className="columns o-od-section u-od-margin-bottom--base">
      <Link className="o-od-section__block u-od-txt--subheader u-od-cs-txt-link--light u-od-cs-bg--gb-regular align-middle align-center" to={{ pathname: linkWithParams('section', s.hierarchy) }}>
        {s.title}
      </Link>
    </div>
  ));
  const materials = map(resource.materials, m => {
    const iconDef = MaterialIcons[m.materialType];
    return (
      <div key={`gm-${m.label}`} className="u-od-flex align-middle u-od-margin-bottom--large">
        <a href={m.url} className="u-od-cs-txt--gb-regular" target="_blank" rel="noopener noreferrer">
          <svg className="o-od-icon-image o-od-icon-image--large">
            <use href={`${materialIcons}#${iconDef}`} />
          </svg>
        </a>
        <span className="u-od-padding-left--base u-od-txt--guidebook-material">{m.label}</span>
      </div>
    );
  });

  let videos = [];

  try {
    videos = JSON.parse(resource.metadata.videos);
  } catch (e) {
    console.error(e);
  }

  let videoTitles = [];

  try {
    videoTitles = JSON.parse(resource.metadata.videoTitles);
  } catch (e) {
    console.error(e);
  }

  const parsedVideos = videos
    .map(video => {
      const videoInfo = urlParser.parse(video);

      if (!videoInfo) {
        return null;
      }

      return urlParser.create({
        videoInfo,
        format: 'embed',
      });
    })
    .filter(function (element) {
      return element !== null;
    });

  return (
    <div className={`u-od-colorset--${guidebookType} row u-od-margin-top--gutter2x u-od-margin-bottom--gutter`}>
      <section className="columns small-12">
        <div className="row">
          <div className="columns small-12 large-8">
            <div>
              <h1>Overview</h1>
              {rawHtml(resource.teaser, 'u-od-txt--content-teaser u-od-cs-txt--gb-dark u-od-margin-top--gutter')}
            </div>
            {videos.length > 0 && (
              <div className="u-od-margin-top--gutter">
                <h1>Unit Video Materials</h1>
                <div className="u-od-txt--content-teaser u-od-cs-txt--gb-dark u-od-margin-top--gutter">
                  <div className="c-grid-video">
                    {parsedVideos.map((video, i) => (
                      <div key={i}>
                        <iframe width="176px" height="144px" className="c-grid-video-item" title={video} src={video} allowFullScreen frameBorder={0}></iframe>
                        <div title={videoTitles[i]} className="c-grid-video-title">
                          {videoTitles[i]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="columns u-od-cs-txt--gb-regular">
            {materials}

            {resource.metadata.teachingGuideUrl && (
              <div key="gm-materials-teaching-guide" className="u-od-flex align-middle u-od-margin-bottom--large">
                <a href={resource.metadata.teachingGuideUrl} className="u-od-cs-txt--gb-regular" target="_blank" rel="noopener noreferrer">
                  <svg className="o-od-icon-image o-od-icon-image--large">
                    <use href={`${materialIcons}#icon-unit-teacher-guide`} />
                  </svg>
                </a>
                <span className="u-od-padding-left--base u-od-txt--guidebook-material">Teaching Guide</span>
              </div>
            )}

            {resource.bundleUrl && (
              <div key="gm-materials-bundle" className="u-od-flex align-middle u-od-margin-bottom--large">
                <a href={resource.bundleUrl} className="u-od-cs-txt--gb-regular" target="_blank" rel="noopener noreferrer">
                  <svg className="o-od-icon-image o-od-icon-image--large">
                    <use href={`${materialIcons}#icon-materials`} />
                  </svg>
                </a>
                <span className="u-od-padding-left--base u-od-txt--guidebook-material">Materials</span>
              </div>
            )}

            {resource.twrMaterialsUrl && (
              <div key="gm-materials-twr" className="u-od-flex align-middle u-od-margin-bottom--large">
                <a href={resource.twrMaterialsUrl} className="u-od-cs-txt--gb-regular" target="_blank" rel="noopener noreferrer">
                  <svg className="o-od-icon-image o-od-icon-image--large">
                    <use href={`${materialIcons}#icon-materials`} />
                  </svg>
                </a>
                <span className="u-od-padding-left--base u-od-txt--guidebook-material">TWR Materials</span>
              </div>
            )}

            {resource.supportsMaterialsUrl && (
              <div className="u-od-flex align-middle u-od-margin-bottom--large">
                <a href={resource.supportsMaterialsUrl} className="u-od-cs-txt--gb-regular" target="_blank" rel="noopener noreferrer">
                  <svg className="o-od-icon-image o-od-icon-image--large">
                    <use href={`${materialIcons}#icon-materials`} />
                  </svg>
                </a>
                <span className="u-od-padding-left--base u-od-txt--guidebook-material">Supports</span>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="columns small-12 u-od-margin-top--gutter2x">
        <div className="row">{sections}</div>
      </section>
    </div>
  );
};

Guidebook.propTypes = {
  guidebookType: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Guidebook;
