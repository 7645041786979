import { schema } from 'normalizr';

const activity = new schema.Entity('activities');

const lesson = new schema.Entity('lessons', {
  children: [activity],
});

const section = new schema.Entity('sections', {
  children: [lesson],
});

const guidebook = new schema.Entity('guidebooks', {
  children: [section],
});

const grade = new schema.Entity('grades', {
  children: [guidebook],
});

const data = new schema.Array(grade);

const lessonDocument = {
  materials: [new schema.Entity('materials')],
};

const breadcrumb = {
  data: data,
};

// Schemas for some API responses.
export const Schemas = {
  BREADCRUMB: breadcrumb,
  LESSON: lessonDocument,
};
