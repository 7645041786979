import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, toLower } from 'lodash';
import { Link } from 'react-router-dom';
import { linkWithParams } from '../../routes';

const ActivityHeader = ({ resource, meta }) => {
  const navList = map(resource.navList, a => {
    const activityType = toLower(a.activityType);
    const cls = classNames('o-od-activity-card__number u-od-txt--activity-card-number u-od-custom-link', {
      'u-od-cs-bg--gb-extra u-od-cs-txt--light': activityType !== 'optional',
      'u-od-cs-border--gb-extra u-od-cs-txt--gb-extra': activityType === 'optional',
      'o-od-activity-list__item--active': parseInt(a.number, 10) === meta.activityNumber,
    });
    return (
      <li key={`a-${a.number}`} className="o-od-activity-list__item">
        <Link to={{ pathname: linkWithParams(a.odellCurriculumType, a.hierarchy) }} className={cls}>
          <span className="">{a.number}</span>
        </Link>
      </li>
    );
  });
  return (
    <div className="u-od-flex-row align-middle">
      <h1 className="u-od-margin-right--gutter">
        <b>Activity {resource.activity.number}</b>
      </h1>
      <ul className="o-od-activity-list">{navList}</ul>
    </div>
  );
};

ActivityHeader.propTypes = {
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ActivityHeader;
