import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { loadActivityPage } from '../actions';
import { getTitle } from '../selectors';
import Layout from './Layout';
import ActivityContainer from './activity/ActivityContainer';

class ActivityPage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    title: PropTypes.string,
    slug: PropTypes.string,
    // actions
    loadActivityPage: PropTypes.func.isRequired,
  };

  initialize(meta = {}) {
    this.props.loadActivityPage(this.props.match.params, meta);
  }

  componentDidMount() {
    this.initialize({ source: 'init' });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.match.params, this.props.match.params)) {
      this.initialize();
    }
  }

  render() {
    // TODO: change this & refactor layout to show content transition smoothly
    const isLoading = false; // || !endsWith(this.props.slug, this.props.match.params.lesson)
    return (
      <Layout title={this.props.title} resourceType="activity" withSticky={false} withTopBar={false} withSubHeader={false} isLoading={isLoading}>
        <ActivityContainer />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    slug: state.resource.slug,
    title: getTitle(state.resource, 'title'),
  };
};

export default connect(mapStateToProps, { loadActivityPage })(ActivityPage);
