/* eslint-disable no-constant-condition */
import { take, put, call, fork, select, all } from 'redux-saga/effects';
import { isEmpty, trim, replace } from 'lodash';
import { paramsToSlug } from '../routes';
import * as api from '../services';
import * as actions from '../actions';
import * as internalActions from '../actions/internal';
import { getGrades, getDevise } from '../selectors';
import { rootAnalytics } from './analytics';

// resuable fetch Subroutine
// entity : grades | resources | breadcrumb items etc
// apiFn  : api function
// params : request params
// meta   : meta to pass into success response
function* fetchEntity(entity, apiFn, params, meta = {}) {
  yield put(entity.request(params));
  const { response, error, status } = yield call(apiFn, params);
  if (response) yield put(entity.success(response, meta));
  else {
    if (status === 401) yield put(actions.logout({ error }));
    else yield put(entity.failure(error));
  }
}

// bind Generators
export const fetchBreadcrump = fetchEntity.bind(null, actions.rsaa(actions.BREADCRUMB), api.fetchBreadcrump);
export const fetchGrades = fetchEntity.bind(null, actions.rsaa(actions.GRADES), api.fetchGrades);
export const fetchIwus = fetchEntity.bind(null, actions.rsaa(actions.IWUS), api.fetchIwus);
export const fetchActivity = fetchEntity.bind(null, actions.rsaa(actions.ACTIVITY), api.fetchActivity);
export const fetchResource = fetchEntity.bind(null, actions.rsaa(actions.RESOURCES), api.fetchResources);

function* logout(error) {
  const auth = yield select(getDevise);
  yield put(internalActions.resetStore(error));
  window.location.href = auth.logoutUrl;
}

function* loadActivity(params, meta) {
  const slug = paramsToSlug(params);
  yield call(fetchActivity, { slug }, meta);
}

// load grades unless it is cached
function* loadIwus(meta) {
  yield call(fetchIwus, {}, meta);
}

function* loadBreadcrumb(params, meta) {
  // const navMeta = yield select(getMeta)
  // if (navMeta.resourceId !== params.id) {
  // }
  const slug = paramsToSlug(params);
  yield call(fetchBreadcrump, { slug }, meta);
}

// load grades unless it is cached
function* loadGrades(meta) {
  const grades = yield select(getGrades);
  if (isEmpty(grades)) {
    yield call(fetchGrades, {}, meta);
  }
}

function* loadResource(params, meta) {
  const slug = paramsToSlug(params);
  yield call(fetchResource, { slug }, meta);
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
// Full screen
function* watchFullScreen() {
  while (true) {
    const params = yield take(actions.LOAD_FULL_SCREEN);
    const mode = params.payload;
    let html = document.documentElement;
    if (mode) {
      html.className += ' u-od-mode--fullscreen';
    } else {
      html.className = trim(replace(html.className, 'u-od-mode--fullscreen', ''));
    }
    yield put(internalActions.toggleFullScreen(mode));
  }
}

function* watchIwusPage() {
  yield take(actions.LOAD_IWU_PAGE);
  yield call(loadIwus, { source: 'init' });
}

function* watchActivityPage() {
  while (true) {
    const params = yield take(actions.LOAD_ACTIVITY_PAGE);
    yield call(loadBreadcrumb, params.payload, params.meta);
    yield call(loadActivity, params.payload, params.meta);
  }
}

function* watchLogout() {
  while (true) {
    const params = yield take(actions.LOGOUT);
    yield call(logout, params.payload, params.meta);
  }
}

// Fetches common data
function* watchHomePage() {
  while (true) {
    yield take(actions.LOAD_HOME_PAGE);

    yield call(loadGrades, { source: 'init' });
  }
}

// Fetches data for Resource page except lesson
function* watchResourcePage() {
  while (true) {
    const params = yield take(actions.LOAD_RESOURCE_PAGE);
    yield call(loadBreadcrumb, params.payload, params.meta);
    yield call(loadResource, params.payload, params.meta);
  }
}

export default function* root() {
  yield all([fork(watchActivityPage), fork(watchFullScreen), fork(watchHomePage), fork(watchIwusPage), fork(watchLogout), fork(watchResourcePage), ...rootAnalytics()]);
}
