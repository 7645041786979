import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { linkWithParams } from '../../routes';
import { rawHtml } from '../../utils/Formatters';

const LessonCard = ({ resource, guidebookType }) => {
  return (
    <div className={`o-od-item-card u-od-cs-bg--${guidebookType} u-od-link`}>
      <Link to={{ pathname: linkWithParams('lesson', resource.hierarchy) }}>
        <div className="row is-collapse-child align-middle align-justify u-od-margin-bottom--small">
          <h2 className="u-od-cs-txt--light">Lesson {resource.number}</h2>
        </div>
        {rawHtml(resource.description, 'u-od-txt--content-card u-od-cs-txt--light')}
      </Link>
    </div>
  );
};

LessonCard.propTypes = {
  resource: PropTypes.object.isRequired,
  guidebookType: PropTypes.string.isRequired,
};

export default LessonCard;
