import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/nav/BreadCrumb';
import Iwus from '../../components/iwus/Iwus';

const IwusContainer = ({ resource }) => {
  return (
    <div>
      <BreadCrumb data={resource.breadcrumb} withSticky={false} />
      <Iwus resource={resource} />
    </div>
  );
};

IwusContainer.propTypes = {
  resource: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    resource: state.iwu,
  };
};

export default connect(mapStateToProps, {})(IwusContainer);
