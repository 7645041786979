import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import { get, map, replace } from 'lodash';

class BreadCrumbDropdown extends React.Component {
  static propTypes = {
    active: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    guidebookType: PropTypes.string.isRequired,
    // actions
    push: PropTypes.func.isRequired,
  };

  onSelect = option => {
    this.props.push(`/${replace(option.value, 'ela/', '')}`);
  };

  render() {
    const { active, data, guidebookType } = this.props;
    const options = map(data, d => ({ value: d.slug, label: d.title }));
    return (
      <Dropdown
        options={options}
        onChange={this.onSelect}
        value={get(active, 'slug', 'Unknown')}
        placeholder="Unknown"
        baseClassName="o-od-dropdown"
        placeholderClassName="u-od-txt--breadcrumb u-od-txt--capital"
        menuClassName={`u-od-cs-bg--${guidebookType} u-od-txt--breadcrumb`}
        arrowClosed={<span className="o-od-dropdown-arrow--closed fa fa-angle-up" />}
        arrowOpen={<span className="o-od-dropdown-arrow--open fa fa-angle-up" />}
      />
    );
  }
}

export default BreadCrumbDropdown;
