import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { rawHtml } from '../../utils/Formatters';
import gbApplication from '../../../assets/images/pages/application.svg';
import gbDevelopment from '../../../assets/images/pages/development.svg';
import gbFoundation from '../../../assets/images/pages/foundation.svg';
import twrBranding from '../../../assets/images/layout/twr-branding.png';

const guidebookIcons = {
  application: gbApplication,
  development: gbDevelopment,
  foundation: gbFoundation,
};

const TWR_GRADES = [3, 4, 5];

const Header = ({ guidebook }) => {
  if (isEmpty(guidebook) || isNil(guidebook)) return null;
  const showTwrLogo = TWR_GRADES.includes(parseInt(guidebook.metadata.grade.replace(/\D+/g, '')));
  return (
    <div className={`u-od-cs-bg--${guidebook.guidebookType} u-od-padding-top--gutter-half u-od-padding-bottom--gutter`}>
      <div className="row u-od-flex--baseline">
        <div className="columns shrink u-od-padding-right--zero">
          <img className="o-od-icon-image--book" src={guidebookIcons[guidebook.guidebookType]} alt={guidebook.guidebookType} />
        </div>
        <div className="columns">
          {guidebook.title && <div className="u-od-txt--title u-od-cs-txt--light">{guidebook.title}</div>}
          {guidebook.metadata.question && <div className="u-od-cs-txt--light u-od-txt--subtitle">{rawHtml(guidebook.metadata.question)}</div>}
        </div>
        {showTwrLogo && (
          <div className="columns shrink o-od-header__logo-twr">
            <img className="" src={twrBranding} alt="TWR Materials" />
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  guidebook: PropTypes.object,
  // actions
  push: PropTypes.func.isRequired,
};

export default Header;
