import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getGuidebookType } from '../../selectors';
import BreadCrumb from '../../components/nav/BreadCrumb';

const mapStateToProps = state => {
  return {
    breadcrumb: state.breadcrumb,
    guidebookType: getGuidebookType(state),
  };
};

const SubNavBar = connect(mapStateToProps, { push })(BreadCrumb);

export default SubNavBar;
