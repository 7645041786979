import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExtendedRoute, { appNamedRoutes } from '../routes';
import ActivityPage from './ActivityPage';
import ResourcePage from './ResourcePage';
import HomePage from './HomePage';
import IwusPage from './IwusPage';
import SupportsPage from './SupportsPage';
import NotFound from '../components/NotFound';

const App = () => (
  <div>
    <Switch>
      <Route path="/interactive-supports" excat component={SupportsPage} />
      <Route path={appNamedRoutes.root} exact component={HomePage} />
      <Route path={appNamedRoutes.iwus} exact component={IwusPage} />
      <ExtendedRoute path={appNamedRoutes.grade} exact component={ResourcePage} resourceType="grade" withSticky={false} withSubHeader={false} />
      <ExtendedRoute path={appNamedRoutes.guidebook} exact component={ResourcePage} resourceType="guidebook" withSticky={true} withSubHeader={true} />
      <ExtendedRoute path={appNamedRoutes.section} exact component={ResourcePage} resourceType="section" withSticky={true} withSubHeader={true} />
      <ExtendedRoute path={appNamedRoutes.lesson} exact component={ResourcePage} resourceType="lesson" withSticky={true} withSubHeader={true} />
      <Route path={appNamedRoutes.activity} exact component={ActivityPage} />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default App;
