import * as ActionTypes from '../actions';
import * as EventTypes from '../actions/internal';

const defaultState = {};

export default function resource(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.ACTIVITY.RECEIVE:
    case ActionTypes.RESOURCES.RECEIVE:
      return action.payload;
    case EventTypes.SIGN_OUT:
      return defaultState;
    default:
      return state;
  }
}
