import _ from 'lodash';
import { AirBrakeNotifier, USE_AIR_BRAKE } from './airbrake';
import { Api, MESSAGES } from './api';
import './stylesheets/client.scss';
import widgetTemplate from './templates/widget.ejs';
import widgetCompactTemplate from './templates/widget_compact.ejs';
import widgetS3TransferTemplate from './templates/widget_s3_transfer.ejs';
import widgetS3TransferCompactTemplate from './templates/widget_s3_transfer_compact.ejs';
import { GoogleDrive, retry } from './utils';

const logoDrive = require('./assets/logo_drive_2020q4_color_2x_web_64dp.png');

let airbrake;

if (USE_AIR_BRAKE) {
  airbrake = new AirBrakeNotifier().airbrake;
}

class Widget {
  api;

  link;

  sourceFolderId;

  constructor() {
    this.api = new Api();

    document
      .querySelectorAll(
        '.c-magic-link:not(.c-magic-link--compact, .c-magic-link--s3-transfer, .c-magic-link--s3-transfer-compact)'
      )
      .forEach((link) => {
        const widget = _.template(widgetTemplate);
        link.insertAdjacentHTML('afterend', widget({ link, logoDrive }));
        link.remove();
      });

    document.querySelectorAll('.c-magic-link--compact').forEach((link) => {
      const widget = _.template(widgetCompactTemplate);
      link.insertAdjacentHTML('afterend', widget({ link, logoDrive }));
      link.remove();
    });

    document.querySelectorAll('.c-magic-link--s3-transfer').forEach((link) => {
      const widget = _.template(widgetS3TransferTemplate);
      link.insertAdjacentHTML('afterend', widget({ link, logoDrive }));
      link.remove();
    });

    document.querySelectorAll('.c-magic-link--s3-transfer-compact').forEach((link) => {
      const widget = _.template(widgetS3TransferCompactTemplate);
      link.insertAdjacentHTML('afterend', widget({ link, logoDrive }));
      link.remove();
    });
  }

  handleStandardStart(link) {
    this.link = link;
    this.widget = this.link.closest('.c-widget');
    this.message = this.widget.querySelector('.c-widget__message');
    this.message.classList.remove('o-error');

    this.sourceFolderId = GoogleDrive.urlToId(this.link.dataset.url);
    this.api.handleAuth(async () => {
      if (gapi.client.getToken() === null) {
        this.message.classList.add('o-error');
        this.message.innerHTML = MESSAGES.signIn;
        return;
      }

      this.message.innerHTML = MESSAGES.working;

      const startButton = this.widget.querySelector('.c-widget__start-button');
      startButton.classList.add('o-hidden');

      this.api
        .start(this.sourceFolderId)
        .then((response) => {
          if (!response.ok) {
            this.message.classList.add('o-error');
            this.message.innerHTML = MESSAGES.error;
          }
          return response.json();
        })
        .then((data) => {
          console.log('Lambda function data:', data);

          const targetFromRemote = data.targetFolderId;
          if (targetFromRemote) {
            const url = `https://drive.google.com/drive/folders/${targetFromRemote}`;
            this.message.innerHTML = `<a href='${url}' target='_blank'>${MESSAGES.finish}</a>`;
          } else if (data.error) {
            this.message.innerHTML = data.error;
          }
        })
        .catch((error) => {
          this.message.innerHTML = MESSAGES.error;
          console.error('Error invoking Lambda function:', error);
        });
    });
  }

  async handleS3TransferStart(link) {
    this.link = link;
    this.widget = this.link.closest('.c-widget');
    this.message = this.widget.querySelector('.c-widget__message');
    this.message.classList.remove('o-error');

    const { s3Folder } = this.link.dataset;

    this.api.handleAuth(async () => {
      if (gapi.client.getToken() === null) {
        this.message.classList.add('o-error');
        this.message.innerHTML = MESSAGES.signIn;
        return;
      }

      this.message.innerHTML = MESSAGES.working;

      const startButton = this.widget.querySelector('.c-widget__start-button');
      startButton.classList.add('o-hidden');

      try {
        const driveFolderId = await this.api.startS3Transfer(s3Folder, gapi.client.getToken().access_token);

        const url = `https://drive.google.com/drive/folders/${driveFolderId}`;
        this.message.innerHTML = `<a href='${url}' target='_blank'>${MESSAGES.finish}</a>`;
      } catch (error) {
        this.message.classList.add('o-error');
        this.message.innerHTML = MESSAGES.error;
        console.error('Error invoking Lambda function:', error);
      }
    });
  }

  init() {
    this.api.init().then(() => {
      document.querySelectorAll('.c-widget:not(.c-widget--compact)').forEach((widget) => {
        const startButton = widget.querySelector('.c-widget__start-button');
        const content = widget.querySelector('.c-widget__content');
        const message = widget.querySelector('.c-widget__message');

        content.classList.remove('o-hidden');
        startButton.classList.remove('o-hidden');
        message.innerHTML = MESSAGES.start;
        message.classList.remove('o-hidden');
      });

      document
        .querySelectorAll(
          '.c-widget__start-button:not(.c-widget--s3-transfer .c-widget__start-button, .c-widget--s3-transfer-compact .c-widget__start-button)'
        )
        .forEach((link) => {
          link.addEventListener('click', (evt) => {
            evt.preventDefault();
            this.handleStandardStart(link);
          });
        });

      document
        .querySelectorAll(
          '.c-widget--s3-transfer .c-widget__start-button, .c-widget--s3-transfer-compact .c-widget__start-button'
        )
        .forEach((link) => {
          link.addEventListener('click', (evt) => {
            evt.preventDefault();
            this.handleS3TransferStart(link);
          });
        });
    });
  }
}

window.initBulkDownloader = () => {
  try {
    retry(
      () => {
        gapi.load('client', () => {
          new Widget().init();
        });
      },
      500,
      3
    );
  } catch (error) {
    if (typeof airbrake !== 'undefined') {
      airbrake.notify(JSON.stringify(error));
    }
  }
};
