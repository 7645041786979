import React from 'react';
import { s3Url } from '../../routes';
import PropTypes from 'prop-types';
import logo from 'assets/images/layout/ldoe-logo-hero.png';
import { Link } from 'react-router-dom';

const HomeHero = ({ s3BucketName, urls }) => (
  <div className="row c-od-home-hero">
    <div className="large-6 small-12 columns text-center">
      <img src={logo} alt="Louisina Curriculum Hub" width="400px" />
    </div>
    <div className="large-6 small-12 columns">
      <div className="c-od-home-hero__buttons">
        {urls.grades35 && (
          <a
            className="c-od-home-hero__button o-od-section__block u-od-txt--subheader u-od-cs-txt-link--light u-od-cs-bg--ldoe-light-blue align-middle align-center"
            href={urls.grades35}
            target="_blank"
            rel="noopener noreferrer"
          >
            3-5 Curriculum Guide
          </a>
        )}
        {urls.grades68 && (
          <a
            className="c-od-home-hero__button o-od-section__block u-od-txt--subheader u-od-cs-txt-link--light u-od-cs-bg--ldoe-light-blue align-middle align-center"
            href={urls.grades68}
            target="_blank"
            rel="noopener noreferrer"
          >
            6-8 Curriculum Guide
          </a>
        )}
        {urls.grades912 && (
          <a
            className="c-od-home-hero__button o-od-section__block u-od-txt--subheader u-od-cs-txt-link--light u-od-cs-bg--ldoe-light-blue align-middle align-center"
            href={urls.grades912}
            target="_blank"
            rel="noopener noreferrer"
          >
            9-12 Curriculum Guide
          </a>
        )}
        <Link className="c-od-home-hero__button o-od-section__block u-od-txt--subheader u-od-cs-txt-link--light u-od-cs-bg--ldoe-light-blue align-middle align-center" to="/interactive-supports">
          Interactive Supports
        </Link>
        <a
          className="c-od-home-hero__button o-od-section__block u-od-txt--subheader u-od-cs-txt-link--light u-od-cs-bg--ldoe-light-blue align-middle align-center"
          href={s3Url(s3BucketName, 'static/Purchasing.pdf')}
          target="_blank"
          rel="noopener noreferrer"
        >
          Purchasing
        </a>
      </div>
    </div>
  </div>
);

HomeHero.propTypes = {
  s3BucketName: PropTypes.string.isRequired,
  urls: PropTypes.object,
};

export default HomeHero;
